import React from "react";
import { AUTH_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-2")
    ),
  },

  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "reset-password",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password")
    ),
  },
  {
    key: "otp-verification",
    path: `${AUTH_PREFIX_PATH}/otp-verification`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/otp-verification")
    ),
  },
  {
    key: "reset-password",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password")
    ),
  },
  {
    key: "set-password",
    path: `${AUTH_PREFIX_PATH}/set-password/:user`,
    //search: '?sort=date&order=newest',
    component: React.lazy(() =>
      import("views/auth-views/authentication/set-password")
    ),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
  {
    key: "customer-revision",
    path: `/customer-revision/:id`,
    component: React.lazy(() =>
      import("views/auth-views/customerrevisionFlow/index")
    ),
  },
  // customer order screen
  {
    key: "customerOrderScreen",
    path: "/order-revision/:id",
    component: React.lazy(() =>
      import("views/app-views/customerOrderScreen/index")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard",
    path: "dashboard",
    component: React.lazy(() => import("views/app-views/dashboards/sales")),
  },
  {
    key: "customer-revision",
    path: `${AUTH_PREFIX_PATH}/customer-revision/:id`,
    component: React.lazy(() =>
      import("views/auth-views/customerrevisionFlow/index")
    ),
  },
  {
    key: "customers",
    path: "customers",
    component: React.lazy(() => import("views/app-views/customers")),
  },
  {
    key: "add-customer",
    path: "add-customer",
    component: React.lazy(() =>
      import("views/app-views/customers/add-customer")
    ),
  },
  {
    key: "edit-customer",
    path: "edit-customer",
    component: React.lazy(() =>
      import("views/app-views/customers/edit-customer")
    ),
  },
  {
    key: "orders",
    path: "orders",
    component: React.lazy(() => import("views/app-views/orders")),
  },
  {
    key: "invoice",
    path: "invoice",
    component: React.lazy(() => import("views/app-views/invoice")),
  },
  {
    key: "add-orders",
    path: "add-orders",
    component: React.lazy(() => import("views/app-views/orders/add-orders")),
  },
  {
    key: "view-orders",
    path: "view-orders",
    component: React.lazy(() => import("views/app-views/orders/view-orders")),
  },
  {
    key: "edit-orders",
    path: "edit-orders",
    component: React.lazy(() => import("views/app-views/orders/edit-orders")),
  },
  {
    key: "create-invoice",
    path: "create-invoice",
    component: React.lazy(() =>
      import("views/app-views/invoice/create-invoice")
    ),
  },
  {
    key: "edit-invoice",
    path: "/edit-invoice/:id",
    component: React.lazy(() => import("views/app-views/invoice/edit-invoice")),
  },
  {
    key: "mockups",
    path: "mockups",
    component: React.lazy(() => import("views/app-views/mockups")),
  },
  {
    key: "add-mockups",
    path: "add-mockups",
    component: React.lazy(() => import("views/app-views/mockups/add-mockups")),
  },
  {
    key: "edit-mockups",
    path: "edit-mockups",
    component: React.lazy(() => import("views/app-views/mockups/edit-mockups")),
  },
  {
    key: "view-mockup",
    path: "view-mockup",
    component: React.lazy(() => import("views/app-views/mockups/view-mockup")),
  },
  {
    key: "email-mockuplander",
    path: `email-mockuplander/:id`,
    component: React.lazy(() =>
      import("views/app-views/mockups/MockupEmailLander")
    ),
  },
  {
    key: "add-child-mockups",
    path: "add-child-mockups",
    component: React.lazy(() =>
      import("views/app-views/mockups/add-child-mockups")
    ),
  },
  // {
  //     key: 'product',
  //     path: 'product',
  //     component: React.lazy(() => import('views/app-views/product')),
  // },
  {
    key: "product",
    path: "product",
    component: React.lazy(() => import("views/app-views/product/product-list")),
  },
  {
    key: "add-product",
    path: "add-product",
    component: React.lazy(() => import("views/app-views/product/add-product")),
  },
  {
    key: "edit-product",
    path: "edit-product",
    component: React.lazy(() => import("views/app-views/product/edit-product")),
  },
  {
    key: "catalog",
    path: "catalog",
    component: React.lazy(() => import("views/app-views/catalog")),
  },
  {
    key: "setting",
    path: "setting/*",
    component: React.lazy(() => import("views/app-views/setting")),
  },
  {
    key: "userrolesetting",
    path: "userrolesetting/*",
    component: React.lazy(() => import("views/app-views/setting/Roles")),
  },
  {
    key: "users",
    path: `users/*`,
    component: React.lazy(() => import("views/app-views/users")),
  },
  // {
  //     key: 'setting',
  //     path: 'setting/*',
  //     component: React.lazy(() => import('views/app-views/setting')),
  // },
  {
    key: "add-user",
    path: "add-user",
    component: React.lazy(() => import("views/app-views/users/add-user")),
  },
  {
    key: "edit-user",
    path: "edit-user",
    component: React.lazy(() => import("views/app-views/users/edit-user")),
  },
  {
    key: "profile",
    path: "profile",
    component: React.lazy(() => import("views/app-views/profile")),
  },
  {
    key: "profile",
    path: "profile/*",
    component: React.lazy(() => import("views/app-views/profile")),
  },
  {
    key: "chat",
    path: "chat",
    component: React.lazy(() => import("views/app-views/chat")),
  },
  {
    key: "calendar",
    path: "calendar",
    component: React.lazy(() => import("views/app-views/calendar")),
  },
  {
    key: "scrumboard",
    path: "scrumboard",
    component: React.lazy(() => import("views/app-views/scrumboard")),
  },
];
